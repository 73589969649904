import React from 'react'
import { Box } from 'components/layout/box'
import { Text } from 'components/typography/text'
import * as styles from './TradeNews.css'
import { TradeHistory } from 'components/MavbotsArena/data/data'

interface Props {
	tradeHistory: TradeHistory[]
}

export const TradeNews = ({ tradeHistory }: Props) => {
	return (
		<Box className={styles.messagesWrapper}>
			{tradeHistory.map((news, index) => (
				<Box className={`${styles.messageBox}`} key={index}>
					{/* Header */}
					<Box display="flex" gap={1} justifyContent="space-between" alignItems="center">
						<Text variant="metadata" color="neutral.500">
							{news.datetime}
						</Text>
						<Text variant="metadataBold" color="neutral.100">
							<Text as="span" variant="metadata" color="neutral.500">
								Position Size:{' '}
							</Text>
							{news.size}
						</Text>
					</Box>
					{/* Body */}
					<Box>
						<Text variant="h3" color="neutral.100" style={{ fontWeight: '700' }} textAlign="center">
							{news.message}
						</Text>
					</Box>
				</Box>
			))}
		</Box>
	)
}
