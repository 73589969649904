import React, { useEffect, useRef } from 'react'
import { Box } from 'components/layout/box'
import { createChart, ISeriesApi, ColorType, CrosshairMode, LineStyle } from 'lightweight-charts'
import { Text } from 'components/typography/text'
import * as styles from './BotProfileChart.css'

interface BotProfileChartProps {
	data: any // Replace with proper `Trader` type
}

interface BotProfileChartProps {
	data: any // Replace with proper `Trader` type
}

export const BotProfileChart = ({ data }: BotProfileChartProps) => {
	const chartContainerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!chartContainerRef.current) return

		const chart = createChart(chartContainerRef.current)

		// Create the chart
		chart.applyOptions({
			handleScroll: false,
			handleScale: false,
			width: chartContainerRef.current.clientWidth,
			height: 370,
			autoSize: true,
			layout: {
				background: {
					type: ColorType.Solid,
					color: 'transparent'
				},
				textColor: '#989FAE'
			},
			grid: {
				vertLines: {
					color: 'rgba(68, 74, 88, 0.2)'
				},
				horzLines: {
					color: 'rgba(68, 74, 88, 0.2)'
				}
			},
			leftPriceScale: {
				visible: true,
				ticksVisible: false,
				borderColor: 'rgba(68, 74, 88, 0.2)'
			},
			rightPriceScale: {
				visible: false,
				ticksVisible: false
			},
			crosshair: {
				mode: CrosshairMode.Hidden,
			},

		})

		// Create the Area series (with gradient fill)
		const areaSeries: ISeriesApi<'Area'> = chart.addAreaSeries({
			priceScaleId: 'left',
			lineColor: '#7961F2',
			bottomColor: 'rgba(92,72,196, 0.3)',
			topColor: 'rgba(121, 97, 242, 0.6)',
			lineWidth: 2,
			lastValueVisible: false,
			priceLineVisible: false,
		})

		// Create the Line series
		const lineSeries: ISeriesApi<'Line'> = chart.addLineSeries({
			priceScaleId: 'right',
			color: '#989FAE',
			lineWidth: 2,
			lastValueVisible: false,
			priceLineVisible: false,
	
		})

		// Sample Data for the Area series (can be different from the Line series data)
		const areaChartData = [
			{ time: '2018-01-01', value: 1 },
			{ time: '2018-06-01', value: 1.1 },
			{ time: '2019-01-01', value: -0.4 },
			{ time: '2019-06-01', value: 0.95 },
			{ time: '2020-01-01', value: 1.2 },
			{ time: '2020-06-01', value: 1.15 },
			{ time: '2021-01-01', value: 1.25 },
			{ time: '2021-06-01', value: 1.35 },
			{ time: '2022-01-01', value: 1.4 },
			{ time: '2022-06-01', value: 1.45 },
			{ time: '2023-01-01', value: 1.5 },
			{ time: '2023-06-01', value: 1.55 },
			{ time: '2024-01-01', value: 1.6 }
		]

		// Sample Data for the Line series (can be same or different from Area series)
		const lineChartData = [
			{ time: '2018-01-01', value: 1.2 },
			{ time: '2018-06-01', value: 1.4 },
			{ time: '2019-01-01', value: 0.2 },
			{ time: '2019-06-01', value: 1.2 },
			{ time: '2020-01-01', value: 1.4 },
			{ time: '2020-06-01', value: 2.1 },
			{ time: '2021-01-01', value: 1.2 },
			{ time: '2021-06-01', value: 1.1 },
			{ time: '2022-01-01', value: 1.2 },
			{ time: '2022-06-01', value: 1.8 },
			{ time: '2023-01-01', value: 1.3 },
			{ time: '2023-06-01', value: 1.4 },
			{ time: '2024-01-01', value: 1.8 }
		]

		// Set data for both series
		areaSeries.setData(areaChartData)
		lineSeries.setData(lineChartData)

		chart.timeScale().fitContent()

		function handleResize() {
			chart.timeScale().fitContent()
			// console.log('handleResize', chartContainerRef.current?.clientWidth)
			// chart.applyOptions({ width: chartContainerRef.current?.clientWidth })
		}

		window.addEventListener('resize', handleResize)

		// Clean up the chart on component unmount
		return () => {
			window.removeEventListener('resize', handleResize)
			chart.remove()
		}
	}, [])

	return (
		<div className={styles.chartWrapper}>
			<div className={styles.chartContainer} ref={chartContainerRef} />
		</div>
	)
}
