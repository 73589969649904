import React, { useState, HTMLAttributes } from 'react'
import { Box } from 'components/layout/box'
import * as styles from './Card.css'

import { Typewriter } from '../StateOfMarket/Typewriter/Typewriter'
import { Error } from 'components/Error'
import { CardHeader, CardHeaderProps } from './CardHeader'

type ContentChild = {
	text: string
}

interface CardProps extends Pick<HTMLAttributes<HTMLElement>, 'style' | 'children'>, CardHeaderProps {
	component?: React.JSX.Element
	isError?: boolean
	bodyStyle?: React.CSSProperties
}

export const Card = ({ title, component, tooltip, isError, children, bodyStyle, ...rest }: CardProps) => {
	const [isHovered, setIsHovered] = useState(false)
	const [hasBeenHovered, setHasBeenHovered] = useState(false)

	const handleMouseEnter = () => {
		setIsHovered(true)
		setHasBeenHovered(true)
	}

	const handleMouseLeave = () => {
		setIsHovered(false)
	}

	return (
		<Box
			{...rest}
			className={`${styles.cardContainer} ${isHovered ? styles.hover : hasBeenHovered ? styles.leave : ''}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{title && <CardHeader title={title} tooltip={tooltip} />}
			<Box className={styles.body} style={bodyStyle}>
				{children}
			</Box>
		</Box>
	)
}

export const CardTextEl = ({ content, isError }: { content: ContentChild[]; isError?: boolean }) => {
	if (isError) {
		return <Error />
	}

	return (
		content &&
		content.map((text, index) => (
			<>
				<Typewriter key={index} content={text.text} />
				<br></br>
			</>
		))
	)
}
