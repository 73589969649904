import React from 'react'
import numeral from 'numeral'

import { Box } from 'components/layout/box'
import * as styles from './BotCard.css'

import { RiskChip } from 'components/MavbotsArena/components/RiskChip/RiskChip'
import { Badge } from 'components/MavbotsArena/components/Badge/Badge'
import { Avatar } from 'components/MavbotsArena/components/Avatar/Avatar'
import { Trader } from 'components/MavbotsArena/data/data'
import { Text } from 'components/typography/text'
import { Button } from 'components/inputs/button'
import { GraphIcon } from 'assets/icons/icons'
import { InlineIcon } from 'components/icons/inline-icon'
import { TradeNews } from './TradeNews'
import { TradesList } from 'components/MavbotsArena/components/TradesList/TradesList'
import { tokens } from 'style/theme.css'
import { useTabsContext } from 'components/Tabs/TabsProvider'
import { useArenaContext } from 'context/ArenaContext'

interface BotCardProps {
	data: Trader
}
export const BotCard = ({ data }: BotCardProps) => {
	const { onActiveTabChange } = useTabsContext()
	const { setArenaSelectedBot } = useArenaContext()

	const handleAvatarClick = () => {
		onActiveTabChange('Roster')
		setArenaSelectedBot(data)
	}

	return (
		<Box className={styles.card}>
			<Box className={styles.mainCardGrid}>
				{/* Avatar */}
				<Box className={styles.avatarWrapper}>
					<Avatar avatar={data.avatar} handleClick={handleAvatarClick} />
					<Box display="flex" gap={0.5}>
						{data.badges.map((badge, index) => (
							<Badge key={index} badge={badge} />
						))}
					</Box>
					<RiskChip riskLevel={data.riskLevel} />
				</Box>
				{/* TOP PART */}
				<Box display="flex" flexDirection="column">
					<Box className={styles.headerWrapper}>
						<Box>
							<Text variant="body1Bold">{data.bot_name}</Text>
							<Box>
								<Text variant="body2Bold" color="neutral.100">
									<Text as="span" variant="body2" color="neutral.500">
										Strategy:{' '}
									</Text>
									{data.strategy}
								</Text>
							</Box>
						</Box>

						<Button variant="primary" size="small" style={{ padding: '8px' }}>
							Follow
						</Button>
					</Box>

					{/* BOTTOM PART */}
					<Box className={styles.infoGrid}>
						{/* Details Mid */}
						<Box>
							<TradesList trades={data.trades} openPositions={data.open_positions}>
								<Text as="span" variant="body2" color="neutral.500">
									Trades:{' '}
								</Text>
							</TradesList>
							<Text variant="body2Bold" color="neutral.100" style={{ display: 'flex', gap: '4px' }}>
								<Text as="span" variant="body2" color="neutral.500" style={{ display: 'flex', alignItems: 'center' }}>
									<InlineIcon icon={GraphIcon} style={{ marginRight: '4px' }} />
									YTD Perf:
								</Text>
								<span
									style={{
										color:
											// @ts-ignore
											numeral(data.perf_ytd).value() > 0 ? tokens.colors['primary.500'] : tokens.colors['warning.700']
									}}>
									{data.perf_ytd}
								</span>
							</Text>
						</Box>

						{/* Details Grid */}
						<Box className={styles.detailsGrid}>
							<Text variant="body2Bold" color="neutral.100">
								<Text as="span" variant="body2" color="neutral.500">
									5Y Perf:{' '}
								</Text>
								{data.perf_5y}
							</Text>

							<Text variant="body2Bold" color="neutral.100" textAlign={'right'}>
								<Text as="span" variant="body2" color="neutral.500">
									Avg Win:{' '}
								</Text>
								{data.win_avg}
							</Text>

							<Text variant="body2Bold" color="neutral.100">
								<Text as="span" variant="body2" color="neutral.500">
									Win Rate:{' '}
								</Text>
								{data.win_rate}
							</Text>

							<Text variant="body2Bold" color="neutral.100" textAlign={'right'}>
								<Text as="span" variant="body2" color="neutral.500">
									Avg Loss:{' '}
								</Text>
								{data.loss_avg}
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>

			<TradeNews tradeHistory={data.trade_history} />
		</Box>
	)
}
