import { Trader } from 'api/mavbots-arena/fetchBots'
import React, { createContext, useContext, useState, ReactNode } from 'react'



// Define the context type
interface ArenaContextType {
	arenaSelectedBot: Trader | undefined
	setArenaSelectedBot: (bot: Trader) => void
}

// Create the context with a default value of undefined
const ArenaContext = createContext<ArenaContextType | undefined>(undefined)

// Create a custom hook to use the ArenaContext
export const useArenaContext = () => {
	const context = useContext(ArenaContext)
	if (!context) {
		throw new Error('useArenaContext must be used within an ArenaProvider')
	}
	return context
}

// Create the provider component
interface ArenaProviderProps {
	children: ReactNode
}

export const ArenaProvider = ({ children }: ArenaProviderProps) => {
	const [arenaSelectedBot, setArenaSelectedBot] = useState<Trader | undefined>(undefined)

	return <ArenaContext.Provider value={{ arenaSelectedBot, setArenaSelectedBot }}>{children}</ArenaContext.Provider>
}
