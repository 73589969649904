import "src/style/theme.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/style/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA3WWWW7bMBCG33sKAUaABKhTcri7T5Il3iOLszmxXWdzUvTunaEDlEOyEmzDH0aj2X6Si/12+9L9/tZ18/lzOKzXO7HoxM/sv8T/52D2q6ecAtESqkUnC6QRVXYGoRAnObKIdoecuPQkZ37RASdh0c0gXTm9QBoF3Tm9RDr0Q1yGnF4V+V4vOmd9TlYYCXhmdIPIaoZuMTZjGbqjMHS0ccjpPdJxGKdJ5fShGfK6GfIj0l7RndMnpMGH2E853SA1I9053SLVWveGJbqjYgaIitn+IipBA8tiv+j2t5cXp0p975T93mn8xTkRZ7nRc9NIG2b00jSy3NNr08hzo7emUeBG71TodOX0gHSKEbuS0w9K3fcyDoqV+pPaokPPfUiUzswPFgqMCppZNxpvGUYJzYTQ3hmGFb3TBa051tSxHqbAuiBRSjM1gvbc2lKSgNO0ZNjR4ECQRYAoq5kLVkYmIhnSK5UdOP4S1xg1w6SucRmXk2P4iko1RDfyReWaSmWn2PMAV1+l6tkEyps2RsnNpO2V7hkm2UnjBR9jeZ+srVE8bhKe1EYBt14fx8RPvLCPRyyXPO6nhCdfBLhJeOwdD3Cb8HLQvFQkv2h6kHxOSH9jcE5wvE9tMJPhAT7TKgBaCDbI8oVa7JURvPOvx3Rs5E7ejnHHkafznkTSx57P4CEtc8F47vsjOQk68Hp/NjGQdjAXq9krQTazBNIONljwNQlIO3qUo2T1Bt2UPJg2ts0tBVA7c9y1GPPF3gEh7WwMoVw2282KMdSK2B3SR1r8muOuRosBXZLX5eqfKejCFNiuANdp+TsVHd0/0PPJWXpO4ccefTD7VRn9TXurv23t9XBH1FX4vj4DwANt5bXfdetsAI9Ea7+oMCgZygtqB6guVTKUli4Z6qp6FkVlS4aK8iV7oT6X8JXOCSV8ozLJKqB3wqpO80Dc1rX6+A//bHZB0SHu3Ndc1t1R0OyOUq3uqHSeq+NQpm6PstSe2tS1uqZQSporR6GYbIFQTK5AqCVfINRMKBBqIxiOVvUpVNG5ThUMx18aYn/+Aus/j0EtCwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/components/RightSidePanel/PanelTop/PanelTop.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/RightSidePanel/PanelTop/PanelTop.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA9VW3Y6bOBS+z1NYHUUKUjzCECBDbrbt9Dkqgw3xxtiscTKZVvPutQ0kmGSqSq1UrVCIOb8+3/mO4fErqqvspXoJwfcFAK3smGZS5EBRjjU70Z2RfoNMEHrOAdot3haPowuaueCik/yoncsLI3pvHMJwaR8bJuBVFLZnK9xTVu91DuLwtLfPWrY5CO1K9Qq3bjEhTNQ5OGG1grB7Oh8ObXQIrK6QilAFFSbs2Bl7d8VRH77A5aFW8iiI7/tf4NUEkcutsOgqqZocdCXmdBUGnhhKsydmijSb7Ldn1cwUjPvi+yX1AcoLapypw6mUQlNhqvrwYfcubFcIOK30HTQKqbVsLg9e+UzsqWJ6oikllyrvq2ixMuknyk6/cmrKlZyRiXhok0UStWf7m4NpKmElrG1aE3FltAA8RIm9QLhcD8/xc5R9Md2eCaK5IL4IPmXPn58/gs1FgGIUoQgkc5f0arHJ0k0GthdBGNnL8W4RjCUV0tUAX2hxYBo2uDvkgDNBsbqW8VBVla06GBln3dbvmzlam0gG5MY102BJzyU/EnrDrwknIkeGG7qhwLOKr1YjSzgH4WPaAYo7amJ75pt3zKPBXB61Z5/8ZHIdBccRmrJtoOGgGmiSIH+WN8NsE9a1HL/moOLUCf49dppVr/AyBqW5U2VVmLNaQANg003Ft+XE3c4NkiP1dKQb143yqDqraSUbYwxLSE8mbOfi+EDke3miysExpfi86yhOCK3XQNUFXqEwXoMImRuKN2vLGEM/8JBmz8k2ddQL3tknmrV5SJ/jSg+b+KVT4t6BABHyGoMyvzGj+jIS1g3aU+ZyfiT9WT3F4W69j099fZy1sMU2WSv5ay3FKl6CcO0QuPzb2xo8peMqy5YgRXYRLUGSmYW5gThe+sikv8HQJPGQ2GT3KfoTJOLN/4rF6d9lceYSyxaXTBusTIX9K/8MC04FgY0kNO8DQSJJTUeWESVbWDFuastBwY9qZUgaeMALKfx36tYlu9H/01DCsDlMFaUCYEHAavrNEW1N0wPnOtv2JFbBZXnYOdl94k3fz94b+uZ75+b74m2aefsnM08Pg9k8vC3efgBnlF0u4AkAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var contentBox = '_1gf7wfw1';
export var contentBoxClosing = '_1gf7wfw4';
export var contentBoxExpanded = '_1gf7wfw2';
export var contentBoxOpening = '_1gf7wfw3';
export var leftIconWrapper = '_1gf7wfw6';
export var panelTop = '_1gf7wfw0';
export var rightIconWrapper = '_1gf7wfw5';
export var topBackgroundReflection = '_1gf7wfw8';
export var topReflection = '_1gf7wfw7';