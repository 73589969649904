import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './LatestTrades.css'

import { BotCard } from './BotCard/BotCard'

import { dummyData } from 'components/MavbotsArena/data/data'

export const LatestTrades = () => {
	return (
		<Box className={styles.latestTradesLayout}>
			{dummyData.map(data => (
				<BotCard key={data.bot_id} data={data} />
			))}
		</Box>
	)
}
