import React, { useState, useEffect } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './MavbotsArena.css'
import { MainTitle } from 'components/typography/main-title'
import { TabsProvider } from 'components/Tabs/TabsProvider'
import { Tabs } from 'components/Tabs'

import { MainInfoMavbotsArenaPageDialog } from 'components/Dialog/MainInfoMavbotsArenaPageDialog'
import { Arena } from './Arena'
import { Roster } from './Roster'
import { fetchBots } from 'api/mavbots-arena'
import { useOktaAuth } from '@okta/okta-react'
import { useQuery } from '@tanstack/react-query'
import { Trader } from 'api/mavbots-arena/fetchBots'
import { useArenaContext } from 'context/ArenaContext'

export const MavbotsArena = () => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const {  setArenaSelectedBot } = useArenaContext()

	const { data: arenaBotsData } = useQuery({
		queryKey: ['mavbots-arena', 'mavbots'],
		queryFn: () => fetchBots(accessToken)
	})

	useEffect(() => {
		if (arenaBotsData) {
			const firstBot = Object.keys(arenaBotsData)[0]
			setArenaSelectedBot(arenaBotsData[firstBot])
		}
	}, [arenaBotsData])

	return (
		<Box className={styles.mavbotsArenaContainer}>
			<Box className={styles.header}>
				<MainTitle Dialog={children => <MainInfoMavbotsArenaPageDialog>{children}</MainInfoMavbotsArenaPageDialog>}>
					Mavbots Arena
				</MainTitle>
			</Box>
			<TabsProvider defaultTab="Roster" tabs={['Arena', 'Roster']}>
				<Tabs>
					<Tabs.Tab value="Arena">Arena</Tabs.Tab>
					<Tabs.Tab value="Roster">Roster</Tabs.Tab>

					<Tabs.Panel value="Arena">
						<Arena />
					</Tabs.Panel>
					<Tabs.Panel value="Roster">
						<Roster />
					</Tabs.Panel>
				</Tabs>
			</TabsProvider>
		</Box>
	)
}
