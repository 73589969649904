import React, { useEffect, useState } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Quote.css'
import { Text } from 'components/typography/text'

export const Quote = () => {
	const fullText =
		'You’re not a systematic investor until you can know your max drawdown under various market conditions.'
	const [displayedText, setDisplayedText] = useState('')
	const [blinkerTransform, setBlinkerTransform] = useState('scaleY(0)')

	const [isTyping, setIsTyping] = useState(true)

	useEffect(() => {
		let i = 0
		const speed = 40
		setBlinkerTransform('scaleY(1)')

		const typeWriter = () => {
			if (i < fullText.length) {
				setDisplayedText(fullText.substring(0, i + 1))
				i++
				setTimeout(typeWriter, speed)
			} else {
				setIsTyping(false) // Stop blinking animation when typing is complete
			}
		}

		const startTyping = () => {
			typeWriter()
		}

		const delay = 600
		const timeoutId = setTimeout(startTyping, delay)

		return () => clearTimeout(timeoutId) // Cleanup timeout on unmount
	}, [])

	return (
		<Box className={styles.quoteContainer}>
			<Box className={isTyping ? styles.blinkingBlinker : styles.blinker} style={{ transform: blinkerTransform }}></Box>
			<Text className={styles.typedText}>{displayedText}</Text>
			<Text style={{ opacity: 0 }}>{fullText}</Text>
		</Box>
	)
}
