import React, { useState } from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Roster.css'
import { BotProfile } from './BotProfile'
import { Quote } from './Quote'
import { MeetBots } from './MeetBots'
import { dummyData, Trader } from '../data/data'

interface RosterProps {}

export const Roster = ({}: RosterProps) => {
	const [bot, setBot] = useState<Trader>(dummyData[0])

	return (
		<Box className={styles.rosterContainer}>
			<Quote />
			<BotProfile data={bot} />
			<MeetBots />
		</Box>
	)
}
