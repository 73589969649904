import React from 'react'
import botImg1 from '../../assets/bot.jpg'

import { Box } from 'components/layout/box'
import * as styles from './Avatar.css'
import { Text } from 'components/typography/text'
import clsx from 'clsx'

interface AvatarProps {
	avatar?: string
	handleClick?: () => void
}

export const Avatar = ({ avatar, handleClick }: AvatarProps) => {
	return (
		<Box className={styles.avatar}>
			<Box className={styles.avatarBackground} />
			<Box className={styles.imgWrapper}>
				<img src={avatar} className={styles.img} />
			</Box>
			<Box className={styles.seeStatsLayer} onClick={handleClick}>
				See Stats
			</Box>
		</Box>
	)
}

interface ProfileAvatarProps {
	videoAvatar: string
}

export const ProfileAvatar = ({ videoAvatar }: ProfileAvatarProps) => {
	return (
		<Box className={styles.profileAvatar}>
			<Box className={styles.profileAvatarBackground} />
			<Box className={styles.profileWrapper}>
				<video src={videoAvatar} className={styles.img} autoPlay loop muted />
			</Box>
		</Box>
	)
}

interface MeetAvatarProps {
	avatar: string
	botName: string
	isSelected?: boolean
	handleClick?: () => void
}

export const MeetAvatar = ({ avatar, botName, isSelected, handleClick }: MeetAvatarProps) => {
	return (
		<Box onClick={handleClick}>
			<Box className={clsx(styles.meetAvatar, isSelected && styles.meetAvatarSelected)}>
				<Box className={styles.meetAvatarBackground} />
				<Box className={styles.imgWrapper}>
					<img src={botImg1} className={styles.img} />
				</Box>
			</Box>
			<Text variant="body2Bold" color="neutral.50" textAlign="center">
				{botName}
			</Text>
		</Box>
	)
}
