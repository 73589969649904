import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './MeetBots.css'
import { Card } from 'components/Card'
import { useOktaAuth } from '@okta/okta-react'
import { useQuery } from '@tanstack/react-query'
import { fetchBots } from 'api/mavbots-arena'
import { MeetAvatar } from '../components/Avatar/Avatar'
import { Trader } from 'api/mavbots-arena/fetchBots'
import { useArenaContext } from 'context/ArenaContext'

interface MeetBotsProps {}

export const MeetBots = ({}: MeetBotsProps) => {
	const { oktaAuth } = useOktaAuth()
	const accessToken = oktaAuth.getAccessToken()
	const { arenaSelectedBot, setArenaSelectedBot } = useArenaContext()

	const { data: arenaBotsData } = useQuery({
		queryKey: ['mavbots-arena', 'mavbots'],
		queryFn: () => fetchBots(accessToken)
	})

	return (
		<Box className={styles.meetBotsContainer}>
			<Card
				title="Meet the Bots"
				style={{ minHeight: 'auto' }}
				bodyStyle={{
					marginLeft: '-12px',
					marginRight: '-12px',
					paddingLeft: '24px',
					paddingRight: '24px'
				}}>
				<Box className={styles.botsWrapper}>
					{arenaBotsData &&
						Object.keys(arenaBotsData).map((key: string) => {
							const bot = arenaBotsData[key]
							return (
								<MeetAvatar
									handleClick={() => setArenaSelectedBot(bot)}
									key={key}
									avatar={bot.avatar}
									botName={bot.bot_name}
									isSelected={arenaSelectedBot?.bot_id === bot.bot_id}
								/>
							)
						})}
				</Box>
			</Card>
		</Box>
	)
}
