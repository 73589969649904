import React, { useEffect, useRef, FC } from 'react'
import * as styles from './Typewriter.css'

interface QueueItem {
  from: string
  to: string
  start: number
  end: number
  char?: string
}

class TextScramble {
  el: HTMLElement
  chars: string
  queue: QueueItem[]
  frame: number
  frameRequest?: number
  resolve?: () => void

  constructor(el: HTMLElement) {
    this.el = el
    this.chars = '!<>-_\\/[]{}—=+*^?#________'
    this.queue = []
    this.frame = 0
    this.update = this.update.bind(this)
  }

  setText(newText: string) {
    const oldText = this.el.innerText
    const length = Math.max(oldText.length, newText.length)
    const promise = new Promise<void>((resolve) => (this.resolve = resolve))
    this.queue = []
    for (let i = 0; i < length; i++) {
      const from = oldText[i] || ''
      const to = newText[i] || ''
      const start = Math.floor(Math.random() * 20)
      const end = start + Math.floor(Math.random() * 60)
      this.queue.push({ from, to, start, end })
    }
    cancelAnimationFrame(this.frameRequest!)
    this.frame = 0
    this.update()
    return promise
  }

  update() {
    let output = ''
    let complete = 0
    for (let i = 0, n = this.queue.length; i < n; i++) {
      let { from, to, start, end, char } = this.queue[i]
      if (this.frame >= end) {
        complete++
        output += to
      } else if (this.frame >= start) {
        if (!char || Math.random() < 0.28) {
          char = this.randomChar()
          this.queue[i].char = char
        }
        output += `<span class="dud">${char}</span>`
      } else {
        output += from
      }
    }
    this.el.innerHTML = output
    if (complete === this.queue.length) {
      this.resolve!()
    } else {
      this.frameRequest = requestAnimationFrame(this.update)
      this.frame++
    }
  }

  randomChar() {
    return this.chars[Math.floor(Math.random() * this.chars.length)]
  }
}

interface TypewriterProps {
  content: string
}

export const Typewriter: FC<TypewriterProps> = ({ content = '' }) => {
  const textRef = useRef<HTMLDivElement>(null)
  const textScramble = useRef<TextScramble | null>(null)

  useEffect(() => {
    if (textRef.current) {
      textScramble.current = new TextScramble(textRef.current)
      textScramble.current.setText(content).then(() => {
        // Animation completed
      })
    }

    return () => {
      // Clean up TextScramble instance
      // For example: cancel any animation frames, remove event listeners, etc.
    }
  }, [content])

  return <div className={styles.text} ref={textRef} dangerouslySetInnerHTML={{ __html: content }}></div>
}