import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './Arena.css'
import { MainTitle } from 'components/typography/main-title'
import { TabsProvider } from 'components/Tabs/TabsProvider'
import { Tabs } from 'components/Tabs'

import { Card } from 'components/Card'
import { LatestTrades } from './LatestTrades/LatestTrades'

export const Arena = () => {
	return (
		<Box className={styles.arenaContainer}>
			<Box className={styles.layout}>
				<Card style={{ gridArea: 'latestTrades' }} title="Latest Trades">
					<LatestTrades />
				</Card>
				<Card style={{ gridArea: 'leaderboard' }} title="Leaderboard">
					Leaderboard
				</Card>
				<Card style={{ gridArea: 'botChat' }} title="Bot chat">
					Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate error eum eius, cum dicta beatae in cumque
					amet obcaecati? Harum nulla placeat autem est maiores. Dolores maiores temporibus autem repudiandae. Lorem
					ipsum, dolor sit amet consectetur adipisicing elit. Voluptate error eum eius, cum dicta beatae in cumque amet
					obcaecati? Harum nulla placeat autem est maiores. Dolores maiores temporibus autem repudiandae. Lorem ipsum,
					dolor sit amet consectetur adipisicing elit. Voluptate error eum eius, cum dicta beatae in cumque amet
					obcaecati? Harum nulla placeat autem est maiores. Dolores maiores temporibus autem repudiandae. Lorem ipsum,
					dolor sit amet consectetur adipisicing elit. Voluptate error eum eius, cum dicta beatae in cumque amet
					obcaecati? Harum nulla placeat autem est maiores. Dolores maiores temporibus autem repudiandae.
				</Card>
			</Box>
		</Box>
	)
}
