import React, { lazy, useState, useEffect } from 'react'
import axios from 'axios'
import { Box } from 'components/layout/box'

const ReactPlayer = lazy(() => import('react-player/vimeo'))
const personalAccessToken = '25ece35077fe05447184a20b182f24f1'

export const VideoPlayer = (videoId: { videoId: string }) => {
	// const videoUrl = `https://player.vimeo.com/video/${videoId}`
	const [isPlaying, setIsPlaying] = useState(true)

	useEffect(() => {
		axios
			.get(`https://api.vimeo.com/videos/${videoId}`, {
				headers: {
					Authorization: `Bearer ${personalAccessToken}`
				}
			})
			// .then(response => {
			// 	setBotImg(response.data.pictures.sizes[response.data.pictures.sizes.length - 1].link)
			// })
			.catch(error => {
				console.error('Error:', error)
			})
	}, [videoId])

	const handleVideoEnd = () => {
		setIsPlaying(false)
		console.log('Video has ended')
	}

	if (!isPlaying) {
		return
	}

	return (
		<Box>
			<ReactPlayer
				url={'https://vimeo.com/347119375'}
				width="100%"
				height="100%"
				autoPlay={true}
				playing={true}
				muted={true}
				onEnded={handleVideoEnd}
			/>
		</Box>
	)
}
