import { ReactNode } from 'react'
import { useWindowSize } from '@uidotdev/usehooks'
import * as styles from './MainTitle.css'
import { Box } from 'components/layout/box'
import { breakpoints } from 'style/tokens/breakpoints'

import { CircleInfoIcon } from 'assets/icons/icons'
import { BlockIcon } from 'components/icons/block-icon'
import { IconButton } from 'components/inputs/icon-button'
import { InfoButton } from './InfoButton'

interface MainTitleProps {
	children: ReactNode
	actionChildren?: ReactNode
	Dialog?: (children: ReactNode) => ReactNode
}

export const MainTitle = ({ children, actionChildren, Dialog }: MainTitleProps) => {
	const size = useWindowSize()
	const isTablet = size && size.width && size.width < Number(breakpoints.desktop)

	if (isTablet) {
		return (
			<Box className={styles.mainTitleWrapperMobile}>
				<Box display="flex" alignItems="center">
					<h1 className={styles.mainTitle}>{children}</h1>
					{Dialog && (
						<Box className={styles.iconWrapper}>
							{Dialog(
								<IconButton variant="closeButton" size="xSmall">
									<BlockIcon icon={CircleInfoIcon} size="small" color="neutral.500" />
								</IconButton>
							)}
						</Box>
					)}
				</Box>
				<Box>{actionChildren && <Box className={styles.actionWrapper}>{actionChildren}</Box>}</Box>
			</Box>
		)
	}

	return (
		<Box className={styles.mainTitleWrapperDesktop}>
			<Box></Box>
			<h1 className={styles.mainTitle}>{children}</h1>
			{Dialog && <Box className={styles.iconWrapper}>{Dialog(<InfoButton />)}</Box>}
			{actionChildren && <Box className={styles.actionWrapper}>{actionChildren}</Box>}
		</Box>
	)
}
