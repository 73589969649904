import React from 'react'

import { Box } from 'components/layout/box'
import * as styles from './TradesList.css'

import { Text } from 'components/typography/text'
import { Tooltip } from 'components/Tooltip/Tooltip'

interface TradesListProps {
	trades: string[]
	openPositions: string[]
	children: React.ReactNode
}

interface TooltipContentProps {
	trades: string[]
	openPositions: string[]
}

const TooltipContent = ({ trades, openPositions }: TooltipContentProps) => {
	return (
		<Box display="flex" flexDirection="column" gap={1} style={{ minWidth: '200px' }}>
			<Box display="flex" justifyContent="space-between" gap={2}>
				<Text variant="body2Bold">Trades</Text>
				<Box display="flex" gap={1} alignItems="center">
					<span className={styles.tooltipDot} />
					<Text variant="metadata" color="neutral.100">
						Open positions
					</Text>
				</Box>
			</Box>
			<Box display="flex" gap={2} flexWrap="wrap">
				{trades.map((trade, index) => (
					<Text key={index} variant="body2" color={openPositions.includes(trade) ? 'primary.500' : 'neutral.100'}>
						{trade}
					</Text>
				))}
			</Box>
		</Box>
	)
}

const getTrades = (trades: string[]): string => {
	return trades.map((trade, index) => (index === 0 ? trade : `, ${trade}`)).join('')
}

export const TradesList = ({ trades, openPositions, children }: TradesListProps) => {
	const tradesList = getTrades(trades)

	return (
		<Tooltip content={<TooltipContent trades={trades} openPositions={openPositions} />}>
			<div>
				<Text variant="body2Bold" color="neutral.100" className={styles.tradesText}>
					{children}
					{tradesList}
				</Text>
			</div>
		</Tooltip>
	)
}
