import React from 'react'
import { Helmet } from 'react-helmet'

import { Box } from 'components/layout/box'
import { Container } from 'components/layout/container'
import { MavbotsArena } from 'components/MavbotsArena/MavbotsArena'
import { ArenaProvider } from 'context/ArenaContext'

const MavbotsArenaPage = () => {
	return (
		<Box display="flex" style={{ flexGrow: '1', width: '100%' }}>
			<Helmet>
				<title>MavBots | Arena</title>
				<meta name="description" content="HerdForce MavBots Arena" />
				<meta name="keywords" content="HerdForce, Foxbat, graphs, MavBots, State of markets, Mavbots Arena" />
			</Helmet>
			<Container>
				<ArenaProvider>
					<MavbotsArena />
				</ArenaProvider>
			</Container>
		</Box>
	)
}

export default MavbotsArenaPage
