import React, { useState } from 'react'

import { Box } from 'components/layout/box'

import * as styles from './Sidebar.css'
import { menuList } from './data'
import { MobileNavItem } from './components'

export const MobileSidebar = () => {
	const [openNav, setOpenNav] = useState<string | null>(null)

	const handleToggle = (navId: string) => {
		setOpenNav(prevNav => (prevNav === navId ? null : navId))
	}

	return (
		<Box className={styles.mobileSidebar}>
			{menuList.map(item => (
				<MobileNavItem {...item} key={item.label} isOpen={openNav === item.label} onToggle={handleToggle} />
			))}
		</Box>
	)
}
