import { toRelativeUrl } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import React, { useEffect } from 'react'

import { Sidebar } from 'components/Header/Sidebar'
import { Outlet } from 'components/layout/outlet'

import { MainLayout } from 'components/layout/main-layout'
import { RightSidePanel } from 'components/RightSidePanel'

const RequiredAuth = ({ isAllowed }: { isAllowed: boolean }) => {
	const { oktaAuth, authState } = useOktaAuth()

	useEffect(() => {
		console.log('authState', authState)
		if (!authState) {
			return
		}

		if (!authState?.isAuthenticated) {
			const originalUri = toRelativeUrl(window.location.href, window.location.origin)
			oktaAuth.setOriginalUri(originalUri)
			oktaAuth.signInWithRedirect()
		}
	}, [oktaAuth, authState?.isAuthenticated, authState])

	if (!isAllowed) {
		return <div>Checking user status...</div>
	}

	return (
		<MainLayout>
			<Sidebar />
			<Outlet />
			{/* <Avatar /> */}
			<RightSidePanel />
		</MainLayout>
	)
}

export default RequiredAuth
